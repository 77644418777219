import { ssnLength } from 'common/constants/userConstants';
import { isNotEmptyString } from 'common/utils/check-empty-string';
import dayjs from 'dayjs';
import { isDateValid } from 'helpers/date';
import { emailValidation } from 'validations/email.validation';
import * as Yup from 'yup';

const supportedPropertyImageTypes = ['image/jpg', 'image/jpeg', 'image/png', 'image/heic', 'image/heif'];

const isImgFile = (file: File) => supportedPropertyImageTypes.includes(file?.type);

const alphabetValidation = Yup.string()
  .matches(/^[A-Za-z\s]*$/, 'Field can not be numeric or contain special characters')
  .max(255);

const onlyJPGFileAllowedMessage = 'Only JPG or PNG file is allowed';
// const isJPGFile = (file: File) => file.type === 'image/jpeg';

const builtMaximumAllowedFileSizeMessage = (fileSize: number) =>
  `${kbToMb(fileSize)} MB is the maximum allowed size for a file`;

const mbToKb = (mb: number) => mb * 1024 * 1024;
const kbToMb = (mb: number) => mb / 1024 / 1024;

const yupNullableJpg75MbFileObjectSchema = Yup.object({
  file: Yup.mixed()
    .test({
      message: builtMaximumAllowedFileSizeMessage(mbToKb(75)),
      test: (file) => {
        if (!file) return true;
        return (file as File).size <= mbToKb(75);
      },
    })
    .test({
      message: onlyJPGFileAllowedMessage,
      test: (file) => {
        if (!file) return true;
        return isImgFile(file as File);
      },
    }),
});

export const renterProfileValidation = Yup.object({
  firstName: alphabetValidation.required('First name is required'),
  lastName: alphabetValidation.required('Last name is required'),
  email: emailValidation,
  dateOfBirth: Yup.date()
    .min(new Date(new Date().getFullYear() - 100, 0, 1), `Date can't be earlier than 100 years ago`)
    .nullable()
    .transform((curr, orig) => {
      return orig === '' && orig === 'Invalid Date' ? null : curr;
    })
    .typeError('Invalid date')
    .test({
      message: 'Date of birth can not be Less than 18 years from current date',
      test: (value) => dayjs(value).isSameOrBefore(dayjs().subtract(18, 'year'), 'day'),
    }),
  phoneNumber: Yup.string().required('Phone number is required'),
  // driversLicenseNumber: Yup.string(),
  // driversLicenseState: Yup.string(),
  // driversLicenseIssueDate: Yup.date()
  //   .nullable()
  //   .transform((curr, orig) => {
  //     return orig === '' && orig === 'Invalid Date' ? null : curr;
  //   })
  //   .typeError('Invalid date')
  //   .max(dayjs(), 'Drivers license issue date date must be less than current date')
  //   .when('dateOfBirth', ([dateOfBirth], schema) => {
  //     if (dateOfBirth && isDateValid(dateOfBirth)) {
  //       return schema.min(dateOfBirth, 'Drivers license issue date must be after date of birth');
  //     }
  //     return schema;
  //   })
  //   .max(
  //     new Date(new Date().getFullYear() + 50, 0, 1),
  //     'Drivers license issue date cannot be more than 50 years in the future',
  //   ),
  // driversLicenseExpirationDate: Yup.date()
  //   .nullable()
  //   .transform((curr, orig) => {
  //     return orig === '' && orig === 'Invalid Date' ? null : curr;
  //   })
  //   .typeError('Invalid date')
  //   .test({
  //     message: 'Drivers license expiration date can not be past date',
  //     test: (value) => {
  //       return dayjs(value).isSameOrAfter(dayjs(), 'day');
  //     },
  //   })
  //   .when('driversLicenseIssueDate', ([driversLicenseIssueDate], schema) => {
  //     if (driversLicenseIssueDate && isDateValid(driversLicenseIssueDate)) {
  //       return schema.min(
  //         dayjs(driversLicenseIssueDate).add(1, 'day'),
  //         'Drivers license expiration date must be after Drivers license issue date',
  //       );
  //     }
  //     return schema;
  //   })
  //   .max(
  //     new Date(new Date().getFullYear() + 50, 0, 1),
  //     'Drivers license expiration date cannot be more than 50 years in the future',
  //   ),
  // driverLicense: yupNullableJpg75MbFileObjectSchema,
  // passport: yupNullableJpg75MbFileObjectSchema,
  // isSSNNumber: Yup.bool(),
  socialSecurityNumber: Yup.string()
    .length(ssnLength, `Social security number should be exactly ${ssnLength} characters`)
    .required('Social security number is required'),
  gra: Yup.string(),
  // passportNumber: Yup.string(),
  // passportCountry: Yup.string(),
  // passportIssueDate: Yup.date()
  //   .nullable()
  //   .transform((curr, orig) => {
  //     return orig === '' && orig === 'Invalid Date' ? null : curr;
  //   })
  //   .typeError('Invalid date')
  //   .max(dayjs(), 'Passport issue date date must be less than current date')
  //   .when('dateOfBirth', ([dateOfBirth], schema) => {
  //     if (dateOfBirth && isDateValid(dateOfBirth)) {
  //       return schema.min(dateOfBirth, 'Passport Issue date must be after date of birth');
  //     }
  //     return schema;
  //   })
  //   .max(
  //     new Date(new Date().getFullYear() + 50, 0, 1),
  //     'Passport issue date cannot be more than 50 years in the future',
  //   ),
  // passportExpirationDate: Yup.date()
  //   .nullable()
  //   .transform((curr, orig) => {
  //     return orig === '' && orig === 'Invalid Date' ? null : curr;
  //   })
  //   .typeError('Invalid date')
  //   .test({
  //     message: 'Passport expiration date can not be past date',
  //     test: (value) => {
  //       return dayjs(value).isSameOrAfter(dayjs(), 'day');
  //     },
  //   })
  //   .when('passportIssueDate', ([passportIssueDate], schema) => {
  //     if (passportIssueDate && isDateValid(passportIssueDate)) {
  //       return schema.min(
  //         dayjs(passportIssueDate).add(1, 'day'),
  //         'Passport expiration date must be after Passport issue date',
  //       );
  //     }
  //     return schema;
  //   })
  //   .max(
  //     new Date(new Date().getFullYear() + 50, 0, 1),
  //     'Passport expiration date cannot be more than 50 years in the future',
  //   ),
  // socialSecurityNumber: Yup.string().when('isSSNNumber', {
  //   is: true,
  //   then: (schema) => schema.notRequired(),
  //   otherwise: (schema) => schema.length(9, 'Social Security Number must be exactly 9 characters'),
  // }),
  address: Yup.string(),
  city: alphabetValidation,
  state: Yup.string(),
  zipCode: Yup.string(),

  addressField: Yup.string()
    .label('Current address')
    .test(
      'can-be-submitted-address',
      'Please provide a valid address (Property Number/Street Name/City, State, [Zip])',
      function (value) {
        if (isNotEmptyString(value || '')) {
          const address = this.resolve(Yup.ref('address')) as string;
          const city = this.resolve(Yup.ref('city')) as string;
          const state = this.resolve(Yup.ref('state')) as string;
          //const zip = this.resolve(Yup.ref('zipCode')) as string;
          const nonEmptyStrings = [address, city, state /*, zip*/].filter(isNotEmptyString);

          if (nonEmptyStrings.length !== 3) {
            return false;
          }
        }
        return true;
      },
  ),
  durationYears: Yup.number(),
  durationMonths: Yup.number(), 
  
  // companyAddress: Yup.string(),
  // companyCity: alphabetValidation,
  // companyState: Yup.string(),
  // companyZip: Yup.string(),

  // companyAddressField: Yup.string().test(
  //   'can-be-submitted-company-address',
  //   'Please provide a valid company address (Property Number/Street Name/City, State, Zip)',
  //   function (value) {
  //     if (isNotEmptyString(value || '')) {
  //       const address = this.resolve(Yup.ref('companyAddress')) as string;
  //       const city = this.resolve(Yup.ref('companyCity')) as string;
  //       const state = this.resolve(Yup.ref('companyState')) as string;
  //       const zip = this.resolve(Yup.ref('companyZip')) as string;
  //       const nonEmptyStrings = [address, city, state, zip].filter(isNotEmptyString);

  //       if (nonEmptyStrings.length !== 4) {
  //         return false;
  //       }
  //     }
  //     return true;
  //   },
  // ),

  educationAddress: Yup.string(),
  educationCity: alphabetValidation,
  educationState: Yup.string(),
  educationZip: Yup.string(),
  educationAddressField: Yup.string().test(
    'can-be-submitted-educationAddressField',
    'Please provide a valid education address (Property Number/Street Name/City, State, Zip)',
    function (value) {
      if (isNotEmptyString(value || '')) {
        const address = this.resolve(Yup.ref('educationAddress')) as string;
        const city = this.resolve(Yup.ref('educationCity')) as string;
        const state = this.resolve(Yup.ref('educationState')) as string;
        const zip = this.resolve(Yup.ref('educationZip')) as string;
        const nonEmptyStrings = [address, city, state, zip].filter(isNotEmptyString);

        if (nonEmptyStrings.length !== 4) {
          return false;
        }
      }
      return true;
    },
  ),
  institutionName: Yup.string(),
  educationEarned: Yup.string(),
  //jobTitle: alphabetValidation,
  //employmentType: Yup.string(),
  //companyName: Yup.string(),
  //employmentYears: Yup.string()
  //  .matches(/^[1-9][0-9]?$/, "Field should be numeric and don't start from 0")
  //  .max(2),
  //employmentMonths: Yup.string()
  //  .matches(/^[1-9][0-9]?$/, "Field should be numeric and don't start from 0")
  //  .max(2),
  //householdIncome: Yup.string(),
  //retired: Yup.bool().when('selfEmployed', {
  //  is: true,
  //  then: (schema) => schema.isFalse('User cannot have two checked boxes at the same time'),
  //}),
  //selfEmployed: Yup.bool(),

  residences: Yup.array()
    .of(Yup.object({
        address: Yup.string(),
        city: Yup.string(),
        state: Yup.string(),
        zipCode: Yup.string(),        
        
        landlordName: Yup.string()
          .label('Landlord name')
          .min(2, "It is not valid name. Landlord name is too short")
          .max(255, 'Landlord name is too long')
          .matches(/^[a-zA-Z\s\-]+$/, 'Landlord name is invalid')
          .notRequired(),
        
        landlordEmail: Yup.string()
          .label('Landlord email')
          .notRequired(),
        
        phoneNumber: Yup.string()
          .label('Phone number')
          .min(10, 'Invalid phone number')
          .notRequired(),
        
        durationYears: Yup.number()
          .label('Years')
          .min(0, 'Only positive numbers alowed')
          .positive('Only positive numbers alowed')
          .notRequired(),
        
        durationMonths: Yup.number()
          .label('Months')
          .min(0, 'Only positive numbers alowed')
          .positive('Only positive numbers alowed')
    })
  ),
  employmnents: Yup.array()
    .of(Yup.object({
      jobTitle: Yup.string()
        .label('Employment position')
        .max(255, 'Employment position is too long (up to 255 chars)')
        .notRequired(),      
      
      type: Yup.string()
        .label('Employment type')
        .notRequired(),

      companyName: Yup.string()
        .label('Company name')
        .max(255, 'Company name is too long (up to 255 chars)')
        .notRequired(),

      addressLine: Yup.string(),
      address: Yup.string(),
      city: Yup.string(),
      state: Yup.string(),
      zipCode: Yup.string(),
      
      durationYears: Yup.number()
        .label('Years')
        .notRequired(),

      durationMonths: Yup.number()
        .label('Months')
        .notRequired(),
      
      householdIncome: Yup.number()
        .label('Household income')
        .notRequired(),    
      
      retired: Yup.boolean()
        .label('Retired')
        .notRequired(),

      selfEmployed: Yup.boolean()
        .label('Self employed')
        .notRequired()
    })
  ),
  spouse: Yup.object({

    firstName: Yup.string()      
      .label('First name')
      .min(2, 'First name is invalid')
      .max(100, 'First name is too long')
      .matches(/^[a-zA-Z\s\-]+$/, 'First name is invalid')
      .notRequired(),

    lastName: Yup.string()
      .label('Last name')
      .min(2, 'Last name is invalid')
      .max(100, 'Last name is too long')
      .matches(/^[a-zA-Z\s\-]+$/, 'Last name is invalid')
      .notRequired(),

    dateOfBirth: Yup.date()
      .label('Date of brth')
      .notRequired(),

    socialSecurityNumber: Yup.string()
      .label('Social security number')
      .length(9, 'Social security number is invalid (expects 9 digits)')
      .notRequired(),

    addressLine: Yup.string().notRequired(),
    address: Yup.string().notRequired(),
    city: Yup.string().notRequired(),
    state: Yup.string().notRequired(),
    zipCode: Yup.string().notRequired(),

    phoneNumber1: Yup.string()
      .label('Pnone number')
      .min(10, "Pnone number is not valid. It's too short")
      .notRequired(),
      
    phoneNumber2: Yup.string()
      .label('Phone number')
      .notRequired(),

    driverLicense: Yup.string()
      .label('Driver license')
      .min(6, 'Driver license is invalid')
      .max(15, 'Driver license is too long')
      .matches(/^([A-Z]{1,2})?\d+$/, 'Driver license is invalid')
      .notRequired()
  }),
  
  spouseEmployments: Yup.array()
    .of(Yup.object({
      employerName: Yup.string(),
      address: Yup.string(),
      city: Yup.string(),
      state: Yup.string(),
      zipCode: Yup.string(),
      employedAt: Yup.string(),
      monthlyIncome: Yup.number().min(0, '')
    })
  ),
  
  creditObligations: Yup.array()
    .of(Yup.object({
      obligationType: Yup.string()
        .label('Type')
        .required(),
      amount: Yup.number()
        .label('Amount')
        .when('obligationType', {
          is: true,
          then: (schema) => schema.required(),
          otherwise: (schema) => schema.notRequired()
        })
    })
  )
});
